import { Button, darken } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';

export const ErrorButton = withTheme(styled(Button)`
	background-color: ${(props) => props.theme.palette.error.main};
	&:hover {
		background-color: ${(props) => props.theme.palette.error.dark};
	}
	& .MuiButton-label {
		color: white;
	}
`);

export const SuccessButton = withTheme(styled(Button)`
	background-color: ${(props) => props.theme.palette.success.main};
	&:hover {
		background-color: ${(props) => props.theme.palette.success.dark};
	}
	& .MuiButton-label {
		color: white;
	}
`);

export const MainQueueActionButton = withTheme(styled(Button)`
	margin: 0 0.8rem;
	padding: 0.8rem 1rem;
	border-radius: 8px;
	font-size: 1rem;
	box-shadow: ${(props) => props.theme.shadows[7]};
`);

export const ReserveButton = withTheme(styled(Button)`
	margin: 1.25rem;
	padding: 0.8rem 1rem;
	border-radius: 8px;
	font-size: 1rem;
	box-shadow: ${(props) => props.theme.shadows[7]};
`);

export const ErrorMainQueueActionButton = withTheme(styled(
	MainQueueActionButton
)`
	background: ${(props) => props.theme.palette.error.main};
	&:hover {
		background: ${(props) => darken(props.theme.palette.error.dark, 0.1)};
	}
	& .MuiButton-label {
		color: white;
	}
`);
