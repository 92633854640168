import { useState } from 'react';
import { Printer, PrinterInput } from '../../../../redux/Printer/printeSlice.model';
import { Role } from '../../../../redux/User/userSlice.models';
import { TableRow, TableCell, IconButton, Modal, lighten, Paper, ToggleButton, } from '@mui/material';
import { faGear, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { StyledForm } from '../../../../styledcomponents/SimpleForms/SimpleForm';
import { StyledTextField, StyledButton } from '../PrinterForm';
import styled from 'styled-components';
import { TypographyIcon } from '../../../../styledcomponents/Typography/Typography';
import EditIcon from '@mui/icons-material/Edit';
import { clearQueue } from '../../../../redux/Queue/queueSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import PrintIcon from '@mui/icons-material/Print';
import ErrorIcon from '@mui/icons-material/Error';
import AutorenewIcon from '@mui/icons-material/Autorenew';

interface PrinterRowProps {
	printer: Printer;
	deletePrinter: (id: number) => void;
	deleteColor: string;
	role: Role | undefined;
	handlePrinterUpdate: (printer: PrinterInput) => void;
	selectedPrinter: PrinterInput;
	printerState: PrinterStatus;
}

export type PrinterStatus = "Available" | "Disabled" | "Pending" | "InUse" | "Inoperable";
const PrinterRow = ({
	printer,
	deletePrinter,
	deleteColor,
	role,
	selectedPrinter,
	handlePrinterUpdate

}: PrinterRowProps) => {
	const [modalOpen, setModalOpen] = useState(false);
	const [modelName, setModelName] = useState<string>(selectedPrinter.modelName);
	const [locationId] = useState<number>(selectedPrinter.locationId);
	const [printerSpecifics, setPrinterSpecifics] = useState<string>(selectedPrinter?.printerSpesifics);

	const dispatch = useAppDispatch();

	const [printerState, setPrinterState] = useState<PrinterStatus>(selectedPrinter.printerState);

	const handleUpdateSubmit = async (event: React.SyntheticEvent) => {
		event.preventDefault();

		const updatedPrinter: PrinterInput = {
			printerSpesifics: printerSpecifics,
			modelName,
			locationId: locationId,
			printerState: printerState,
			id: selectedPrinter?.id || 0,
		};
		try {
			await handlePrinterUpdate(updatedPrinter);
			handleModalClose();
			
		} catch (error) {
			console.error("Error updating printer:", error);
		}
	}

	const handleClearQueue = () => {
		const newState = printerState === 'Available' ? 'Disabled' : 'Available';
		setPrinterState(newState);

		if (newState === 'Disabled') {
			dispatch(clearQueue(printer.id));
		}
	};

	

	const handleModalOpen = () => {
		setModalOpen(true);
	};

	const handleModalClose = () => {
		setModalOpen(false);
	};

	return (
		<>
			<TableRow key={printer.id}>
				<TableCell component="th" scope="row">
					{printer.modelName}
				</TableCell>
				<TableCell align="right">{printer.printerSpesifics}</TableCell>
				{role === 'SuperAdmin' && (
					<TableCell align="right">{printer.locationName}</TableCell>
				)}
				<TableCell align="right">
					{printer.printerState}

					{printer.printerState === 'Disabled' && (
						<ErrorIcon style={{ color: 'red', marginLeft: '0px', height: '15px' }} />
					)}

					{printer.printerState === 'Pending' && (
						<AutorenewIcon style={{ color: 'orange', marginLeft: '0px', height: '15px', marginTop: '2px', alignItems: 'center' }} />
					)}

					{printer.printerState !== 'Disabled' && printer.printerState !== 'Pending' && printer.printerState !== 'InUse' && (
						<span style={{ marginLeft: '17px' }}></span>
					)}
				</TableCell>
				<TableCell align="right">
					<IconButton
						size="small"
						color="primary"
						onClick={handleModalOpen}
					>
						<FontAwesomeIcon icon={faGear} />
					</IconButton>
				</TableCell>
				<TableCell align="right">
					<IconButton
						size="small"
						disabled={printer.printerState === "Pending"}
						onClick={() => deletePrinter(printer.id)}
					>
						<FontAwesomeIcon
							icon={faTrashAlt}
							color={
								printer.numUsersInQueue > 0
									? lighten(deleteColor, 0)
									: deleteColor
							}
						/>
					</IconButton>
				</TableCell>
			</TableRow>
			<Modal

				className='myModal'
				open={modalOpen}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>

				<StyledPaper>
					<TypographyIcon marginx="large" variant="h5" justifyContent="center">
						<EditIcon /> {t('Rediger printer')}
					</TypographyIcon>
					<StyledForm onSubmit={handleUpdateSubmit}>
						<StyledTextField
							label={t('Modelname')}
							value={modelName}
							required={true}
							onChange={(event) => setModelName(event.target.value)}
						/>
						<StyledTextField
							label={t('Type')}
							required={true}
							value={printerSpecifics}
							onChange={(event) => setPrinterSpecifics(event.target.value)}
						/>


						<ToggleButton
							value="check"
							selected={printerState === 'Available'}
							onChange={handleClearQueue}
						>
							{printerState}
							{printerState === 'Available' && (
								<PrintIcon style={{ color: 'green', marginLeft: '8px' }} />
							)}
							{printerState === 'Pending' && (
								<AutorenewIcon style={{ color: 'orange', marginLeft: '8px', }} />
							)}
							{printerState === 'Disabled' && (
								<ErrorIcon style={{ color: 'red', marginLeft: '8px', }} />
							)}
							

						</ToggleButton>



						<StyledButton type="submit" variant="contained" color="secondary">
							{t('Lagre')}
						</StyledButton>
						<StyledButton type="submit" variant="contained" onClick={handleModalClose} color="secondary">
							{t('Avbryt')}
						</StyledButton>

					</StyledForm>



				</StyledPaper>

			</Modal>
		</>
	);
};


const StyledPaper = styled(Paper)`
  padding: 1rem;
  position: absolute;
  width: 400px;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  place-items: center;
  flex-direction: column;
`;

export default PrinterRow;
