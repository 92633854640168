import React, { useState } from 'react';
import {
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TablePagination,
} from '@mui/material';
import StatisticsRow from './rows/StatisticsRow';
import { PrintStatsResponse } from '../../../redux/Stats/stats.models';
import { TableWrapper, StyledTableContainer } from './AdminTable';

interface SortableTableProps {
	titles: string[];
	rows: PrintStatsResponse[];
}

const SortableTable: React.FC<SortableTableProps> = ({ titles, rows }) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};
	
	return (
		<>
			<TableWrapper>
				<StyledTableContainer component={Paper}>

					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								{titles.map((title, index) => (
									<TableCell key={index}>{title}</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<StatisticsRow key={row.id} stats={row} />
								))}
						</TableBody>

					</Table>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						component="table"
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</StyledTableContainer>
			</TableWrapper>
		</>
	);
};

export default SortableTable;
