import { getAllPrintersOnLocation } from './../Printer/printerSlice';
import { AppDispatch } from './../store';
import { QueueApi } from './../../api/queue.api';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { parseISO } from 'date-fns';
import { ApiStatus } from '../models';
import { RootState, ThunkAPI } from '../store';
import {
	QueueItemObj,
	EnqueuedUser,
	QueueState,
	GetCompletedEnqueuedUsersRequest,
	CompletedEnqueuedUserResponse,
	CompletedEnqueuedUser,
	UserVipObj,
} from './../../models/Queue.models';
import {
	EnqueuedUsersResponse,
	StartOrProlongPrintInput,
} from './queueSlice.models';
import { TimeUtils } from '../../utils/time.utils';

export interface IQueueState {
	enqueuedUsers: EnqueuedUser[];
	enqueuedUsersStatus: ApiStatus;
	completedEnqueuedUsers: CompletedEnqueuedUser[];
	completedEnqueuedUsersStatus: ApiStatus;
	enqueueCurrentUserStatus: ApiStatus;
	currentUserInQueue: boolean;
	currentUserInPrinterId: number;
	getCurrentUserInQueueStatus: ApiStatus;
	unenqueueCurrentUserStatus: ApiStatus;
	startOrProlongPrintStatus: ApiStatus;
	moveBackwardsInQueueStatus: ApiStatus;
	deleteFirstUserInQueueStatus: ApiStatus;
	remainingTimeInStateSec: number;
	printDurationSec: number;
	numberOfRestarts: number;
	restartPrintInQueue: ApiStatus;
}

const initialState: IQueueState = {
	enqueuedUsers: [],
	completedEnqueuedUsers: [],
	enqueuedUsersStatus: 'idle',
	completedEnqueuedUsersStatus: 'idle',
	enqueueCurrentUserStatus: 'idle',
	currentUserInQueue: false,
	currentUserInPrinterId: 0,
	getCurrentUserInQueueStatus: 'idle',
	restartPrintInQueue: 'idle',
	unenqueueCurrentUserStatus: 'idle',
	startOrProlongPrintStatus: 'idle',
	moveBackwardsInQueueStatus: 'idle',
	deleteFirstUserInQueueStatus: 'idle',
	remainingTimeInStateSec: 0,
	printDurationSec: 0,
	numberOfRestarts: 0,	
};

export const getEnqueuedUsers = createAsyncThunk(
	'queue/getEnqueuedUsers',
	async (_, thunkApi: ThunkAPI): Promise<EnqueuedUser[]> => {
		const state: RootState = thunkApi.getState();
		const activePrinterId = state.printer.activePrinterId;

		if (activePrinterId === null) {
			throw new Error('Active printer ID is null');
		}
		const data: EnqueuedUsersResponse[] = await QueueApi.getEnqueuedUsers(
			activePrinterId
		);
		//store the printDurationSec of the first user in the queue
		if (data.length > 0) {
			const firstUserDurationSec = data[0].queueItemResponse.printDurationSec;
			thunkApi.dispatch(setPrintDurationSec(firstUserDurationSec));

			// numberOfRestarts
			const firstUserNumberOfRestarts =
				data[0].queueItemResponse.numberOfRestarts;
			thunkApi.dispatch(setNumberOfRestarts(firstUserNumberOfRestarts));
		}

		return data.map(
			(item: EnqueuedUsersResponse) =>
				({
					numberOfRestarts: item.queueItemResponse.numberOfRestarts,
					queueItemId: item.queueItemResponse.id,
					position: item.queueItemResponse.position,
					startTime:
						item.queueItemResponse.startTime &&
						parseISO(item.queueItemResponse.startTime),
					endTime:
						item.queueItemResponse.endTime &&
						parseISO(item.queueItemResponse.endTime),
					printDurationSec: item.queueItemResponse.printDurationSec,
					queueState: item.queueItemResponse.queueState,
					remainingTimeInStateSec:
						item.queueItemResponse.remainingTimeInStateSec,
					firstInQueueTime: item.queueItemResponse.firstInQueueTime,
					postponesLeft: item.queueItemResponse.postponesLeft,
					userId: item.userPublicData.id,
					phoneNumber: item.userPublicData.phoneNumber,
					firstName: item.userPublicData.firstName,
					lastName: item.userPublicData.lastName,
					numberOfPreparationAdds: item.queueItemResponse.numberOfPreparationAdds,
					customer: item.queueItemResponse.customer,
					project: item.queueItemResponse.project,
				} as EnqueuedUser)
		);
	},
	{
		condition: (_, { getState }) => {
			const activePrinterId = (getState() as RootState).printer.activePrinterId;

			if (activePrinterId === null) {
				return false;
			}
		},
	}
);

export const enqueueUser = createAsyncThunk(
	'queue/enqueueCurrentUser',
	async (userId: number, thunkApi: ThunkAPI): Promise<QueueItemObj> => {
		const activePrinterId = (thunkApi.getState() as RootState).printer
			.activePrinterId;

		if (activePrinterId === null) throw new Error();

		const queueItem: QueueItemObj = await QueueApi.enqueueCurrentUser(
			userId,
			activePrinterId
		);
		return queueItem;
	},
	{
		condition: (_, { getState }) => {
			const activePrinterId = (getState() as RootState).printer.activePrinterId;

			if (activePrinterId === null) {
				return false;
			}
		},
	}
);

export const enqueueUserVIP = createAsyncThunk(
	'queue/enqueueCurrentUserVIP',
	async (UserVipObj: UserVipObj, thunkApi: ThunkAPI): Promise<QueueItemObj> => {
		const activePrinterId = (thunkApi.getState() as RootState).printer
			.activePrinterId;
		if (activePrinterId === null) throw new Error();

		const queueItem: QueueItemObj = await QueueApi.enqueueCurrentUserVIP(
			UserVipObj.userId,
			activePrinterId,
			UserVipObj.customer,
			UserVipObj.project
		);
		return queueItem;
	},
	{
		condition: (_, { getState }) => {
			const activePrinterId = (getState() as RootState).printer.activePrinterId;

			if (activePrinterId === null) {
				return false;
			}
		},
	}
);
export const clearQueue = createAsyncThunk(
	'queue/clear',
	async (printerId: number, { rejectWithValue }) => {
		try {
			const response = await QueueApi.clearQueue(printerId);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	}
);

export const unenqueueUser = createAsyncThunk(
	'queue/unenqueueCurrentUser',
	async (userId: number): Promise<QueueItemObj> => {
		const queueItem: QueueItemObj = await QueueApi.unenqueueCurrentUser(userId);
		return queueItem;
	}
);

export const startOrProlongPrint = createAsyncThunk(
	'queue/startOrProlongPrint',
	async (
		startOrProlongPrintInput: StartOrProlongPrintInput
	): Promise<QueueItemObj> => {
		const queueItem: QueueItemObj = await QueueApi.startOrProlongPrint(
			startOrProlongPrintInput
		);
		return queueItem;
	}
);

export const moveBackwardsInQueue = createAsyncThunk(
	'queue/moveBackwardsInQueue',
	async (userId: number): Promise<QueueItemObj> => {
		const queueItem: QueueItemObj = await QueueApi.moveBackwardsInQueue(userId);
		return queueItem;
	}
);

export const addPreparationTime = createAsyncThunk(
    'queue/addPreparationTime',
    async (queueItemId: number): Promise<QueueItemObj> => {
        const queueItem: QueueItemObj = await QueueApi.addPreparationTime(queueItemId);
        return queueItem;
    }
);



export const restartPrintInQueue = createAsyncThunk<QueueItemObj>(
	'queue/restartPrintInQueue',
	async (_, thunkApi: ThunkAPI): Promise<QueueItemObj> => {
		const state = thunkApi.getState() as RootState;
		const userId = state.user.userId;
		const printDurationSec = state.queue.printDurationSec;
		const numberOfRestarts = state.queue.numberOfRestarts;

		if (userId && numberOfRestarts !== undefined) {
			return await QueueApi.restartPrint(
				userId,
				printDurationSec,
				numberOfRestarts
			);
		}
		throw new Error('No user id or valid number of restarts found');
	}
);

export const RemoveFromQueue = createAsyncThunk(
	'queue/RemoveFromQueue',
	async (_, { getState, dispatch }) => {
		const { currentUserInPrinterId } = (getState() as RootState).queue;
		if (currentUserInPrinterId) {
			await dispatch(unenqueueUser(currentUserInPrinterId)).unwrap();
		}
	}
);

export const deleteFirstUserInQueue = createAsyncThunk(
	'queue/deleteFirstUserInQueue',
	async (printSuccessful: boolean, thunkApi: ThunkAPI): Promise<void> => {
		const activePrinterId = (thunkApi.getState() as RootState).printer
			.activePrinterId;

		return await QueueApi.deleteFirstUserInQueue(
			activePrinterId,
			printSuccessful
		);
	},
	{
		condition: (_, { getState }) => {
			const activePrinterId = (getState() as RootState).printer.activePrinterId;

			if (activePrinterId === null) {
				return false;
			}
		},
	}
);

export const refreshPrinterAndQueue = () => async (dispatch: AppDispatch) => {
	try {
		await dispatch(getAllPrintersOnLocation()).unwrap();
		await dispatch(getUserInQueueStatus()).unwrap();
		await dispatch(getEnqueuedUsers()).unwrap();
		await dispatch(getCompletedEnqueuedUsers()).unwrap();
	} catch (error) {
		console.error('Failed to refresh printers and queue:', error);
	}
};


export const getCompletedEnqueuedUsers = createAsyncThunk(
	'queue/getCompletedEnqueuedUsers',
	async (_, thunkApi: ThunkAPI): Promise<CompletedEnqueuedUser[]> => {
		const activePrinterId = (thunkApi.getState() as RootState).printer
			.activePrinterId;
		if (activePrinterId === null) {
			throw new Error();
		}

		const getCompletedEnqueuedUsersRequest: GetCompletedEnqueuedUsersRequest = {
			printerId: activePrinterId,
			numberOfUsers: 5,
		};
		const data: CompletedEnqueuedUserResponse[] =
			await QueueApi.fetchCompletedEnqueuedUsers(
				getCompletedEnqueuedUsersRequest
			);

		const completedEnqueuedUsers: CompletedEnqueuedUser[] = data.map(
			(item: CompletedEnqueuedUserResponse) =>
				({
					completedEnqueuedUserId: item.id,
					userId: item.userId,
					queueItemId: item.queueItemId,
					queueState: QueueState.Completed,
					startTime: TimeUtils.parseISODate(item.startTime),
					endTime: TimeUtils.parseISODate(item.endTime),
					originalEndTime: TimeUtils.parseISODate(item.originalEndTime),
					firstInQueueTime: TimeUtils.parseISODate(item.firstInQueueTime),
					finishedTime: TimeUtils.parseISODate(item.finishedTime),
					postponesLeft: item.postponesLeft,
					queueCountWhenFinished: item.queueCountWhenFinished,
					extendPrintTimeCount: item.extendPrintTimeCount,
					printSuccessful: item.printSuccessful,
					firstName: item.firstName,
					lastName: item.lastName,
					phoneNumber: item.phoneNumber,
					printDurationSec: item.printDurationSec,
				} as CompletedEnqueuedUser)
		);

		return completedEnqueuedUsers;
	},
	{
		condition: (_, { getState }) => {
			const activePrinterId = (getState() as RootState).printer.activePrinterId;

			if (activePrinterId === null) {
				return false;
			}
		},
	}
);

export const getUserInQueueStatus = createAsyncThunk(
	'queue/getUserInQueueStatus',
	async (_, { getState }: ThunkAPI): Promise<number> => {
		const { userId } = (getState() as RootState).user;
		if (!userId) {
			return 0;
		}
		const queueItem: EnqueuedUser = await QueueApi.getUserInQueueStatus(userId);
		return queueItem.printerId;
	}
);

export const queueSlice = createSlice({
	name: 'queue',
	initialState,
	reducers: {
		setRemainingTimeInStateSec: (state, action: PayloadAction<number>) => {
			state.remainingTimeInStateSec = action.payload;
		},
		setNumberOfRestarts: (state, action: PayloadAction<number>) => {
			state.numberOfRestarts = action.payload;
		},

		setPrintDurationSec: (state, action: PayloadAction<number>) => {
			state.printDurationSec = action.payload;
		},
		decrementRemainingTimeInStateSec: (
			state,
			action: PayloadAction<number>
		) => {
			const nextRemainingSeconds =
				state.remainingTimeInStateSec - action.payload;
			state.remainingTimeInStateSec =
				nextRemainingSeconds < 0 ? 0 : nextRemainingSeconds;
		},
		setCurrentUserInPrinterId: (state, action: PayloadAction<number>) => {
			state.currentUserInPrinterId = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(getEnqueuedUsers.pending, (state) => {
				state.enqueuedUsersStatus = 'loading';
			})
			.addCase(getEnqueuedUsers.fulfilled, (state, action) => {
				state.enqueuedUsersStatus = 'idle';
				const usersSortedByPosition = action.payload
					.slice()
					.sort((a, b) => a.position - b.position);
				state.enqueuedUsers = [...usersSortedByPosition];
				const firstUser = usersSortedByPosition[0];
				state.remainingTimeInStateSec = firstUser?.remainingTimeInStateSec;
			})
			.addCase(getEnqueuedUsers.rejected, (state, action) => {
				state.enqueuedUsersStatus = 'failed';
				state.enqueuedUsers = [];
			})
			.addCase(enqueueUser.pending, (state) => {
				state.enqueueCurrentUserStatus = 'loading';
			})
			.addCase(enqueueUser.fulfilled, (state, action) => {
				state.enqueueCurrentUserStatus = 'idle';
			})
			.addCase(enqueueUser.rejected, (state, action) => {
				state.enqueueCurrentUserStatus = 'failed';
			})
			.addCase(unenqueueUser.pending, (state) => {
				state.unenqueueCurrentUserStatus = 'loading';
			})
			.addCase(unenqueueUser.fulfilled, (state, action) => {
				state.unenqueueCurrentUserStatus = 'idle';
			})
			.addCase(unenqueueUser.rejected, (state, action) => {
				state.unenqueueCurrentUserStatus = 'failed';
			})
			.addCase(startOrProlongPrint.pending, (state) => {
				state.startOrProlongPrintStatus = 'loading';
			})
			.addCase(startOrProlongPrint.fulfilled, (state, action) => {
				state.startOrProlongPrintStatus = 'idle';
			})
			.addCase(startOrProlongPrint.rejected, (state, action) => {
				state.startOrProlongPrintStatus = 'failed';
			})
			.addCase(moveBackwardsInQueue.pending, (state) => {
				state.moveBackwardsInQueueStatus = 'loading';
			})
			.addCase(moveBackwardsInQueue.fulfilled, (state, action) => {
				state.moveBackwardsInQueueStatus = 'idle';
			})
			.addCase(moveBackwardsInQueue.rejected, (state, action) => {
				state.moveBackwardsInQueueStatus = 'failed';
			})
			.addCase(deleteFirstUserInQueue.pending, (state) => {
				state.deleteFirstUserInQueueStatus = 'loading';
			})
			.addCase(deleteFirstUserInQueue.fulfilled, (state, action) => {
				state.deleteFirstUserInQueueStatus = 'idle';
			})
			.addCase(deleteFirstUserInQueue.rejected, (state, action) => {
				state.deleteFirstUserInQueueStatus = 'failed';
			})
			.addCase(getCompletedEnqueuedUsers.pending, (state) => {
				state.completedEnqueuedUsersStatus = 'loading';
			})
			.addCase(getCompletedEnqueuedUsers.fulfilled, (state, action) => {
				state.completedEnqueuedUsersStatus = 'idle';
				state.completedEnqueuedUsers = action.payload
					.slice()
					.sort(
						(a, b) =>
							(b.finishedTime ? b.finishedTime.getTime() : 0) -
							(a.finishedTime ? a.finishedTime?.getTime() : 0)
					);
			})
			.addCase(getCompletedEnqueuedUsers.rejected, (state, action) => {
				state.completedEnqueuedUsersStatus = 'failed';
			})
			.addCase(getUserInQueueStatus.pending, (state) => {
				state.getCurrentUserInQueueStatus = 'loading';
			})
			.addCase(getUserInQueueStatus.fulfilled, (state, action) => {
				state.getCurrentUserInQueueStatus = 'idle';
				state.currentUserInQueue = true;
				state.currentUserInPrinterId = action.payload;
			})
			.addCase(getUserInQueueStatus.rejected, (state, action) => {
				state.getCurrentUserInQueueStatus = 'failed';
				state.currentUserInQueue = false;
				state.currentUserInPrinterId = 0;
			})
			.addCase(addPreparationTime.fulfilled, (state, action) => {
                // Update state with the new preparation time
                const updatedQueueItem = action.payload;
                const index = state.enqueuedUsers.findIndex(
                    (item) => item.queueItemId === updatedQueueItem.id
                );
                if (index !== -1) {
                    state.enqueuedUsers[index] = {
                        ...state.enqueuedUsers[index],
                        numberOfPreparationAdds: updatedQueueItem.numberOfPreparationAdds,
                        firstInQueueTime: updatedQueueItem.firstInQueueTime,
						remainingTimeInStateSec:updatedQueueItem.remainingTimeInStateSec,
                    };
                }
            })
	},
});

export const {
	setRemainingTimeInStateSec,
	decrementRemainingTimeInStateSec,
	setCurrentUserInPrinterId,
	setPrintDurationSec,
	setNumberOfRestarts,
} = queueSlice.actions;

export const enqueuedUsersSel = (state: RootState) => state.queue.enqueuedUsers;
export const enqueuedUsersStatusSel = (state: RootState) =>
	state.queue.enqueuedUsersStatus;
export const enqueueCurrentUserStatusSel = (state: RootState) =>
	state.queue.enqueueCurrentUserStatus;
export const unenqueueCurrentUserStatusSel = (state: RootState) =>
	state.queue.unenqueueCurrentUserStatus;
export const firstUserInQueueSel = (state: RootState) =>
	state.queue.enqueuedUsers[0];
export const lastUserInQueueSel = (state: RootState) =>
	state.queue.enqueuedUsers[state.queue.enqueuedUsers.length - 1];
export const startOrProlongPrintStatusSel = (state: RootState) =>
	state.queue.startOrProlongPrintStatus;
export const moveBackwardsInQueueStatusSel = (state: RootState) =>
	state.queue.moveBackwardsInQueueStatus;
export const deleteFirstUserInQueueStatusSel = (state: RootState) =>
	state.queue.deleteFirstUserInQueueStatus;
export const remainingTimeInStateSecSel = (state: RootState) =>
	state.queue.remainingTimeInStateSec;
export const completedEnqueuedUsersSel = (state: RootState) =>
	state.queue.completedEnqueuedUsers;
export const completedEnqueuedUsersStatusSel = (state: RootState) =>
	state.queue.completedEnqueuedUsersStatus;
export const currentUserInPrinterIdSel = (state: RootState) =>
	state.queue.currentUserInPrinterId;
export const restartPrintInQueueSel = (state: RootState) =>
	state.queue.restartPrintInQueue;
export default queueSlice.reducer;
