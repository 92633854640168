import { getUserInQueueStatus } from './../Queue/queueSlice';
import { AppDispatch, ThunkAPI, RootState } from './../store';
import { UserApi } from './../../api/user.api';
import {
	createSlice,
	PayloadAction,
	createAsyncThunk,
	createSelector,
} from '@reduxjs/toolkit';
import { ApiStatus } from '../models';
import { UserObj } from '../../models/Queue.models';
import { setAuthorizationToken } from '../../api/api.config';
import {
	UserResponse,
	LoginResponse,
	LoginUserResponse,
	UserRequestBody,
	Role,
} from './userSlice.models';
import { setCurrentUserInPrinterId } from '../Queue/queueSlice';
import { Location } from '../Location/locationSlice.model';
import { LocationApi } from '../../api/location.api';

export const compareNames = (a: string, b: string) => {
	if (a < b) return -1;
	if (a > b) return 1;
	return 0;
};

const alphabeticallySort = (a: UserResponse, b: UserResponse) => {
	const firstNameCompare = compareNames(a.firstName, b.firstName);
	if (firstNameCompare !== 0) return firstNameCompare;
	return compareNames(a.lastName, b.lastName);
};

const getUpdatedUsersState = ({
	user,
	users,
}: {
	user: UserResponse;
	users: UserResponse[];
}) => {
	return [...users?.filter((usr) => usr.id !== user.id), user].sort(
		alphabeticallySort
	);
};

export interface UserState {
	jwtToken?: string;
	userId?: number;
	role?: Role;
	phoneNumber?: string;
	firstName?: string;
	lastName?: string;
	locationId?: number | null;
	locationName?: string;
	ipAddress?: string;
	users?: UserResponse[];
	gramFilamentUsedCurrentMonth?: number;
	registerUserStatus: ApiStatus;
	authenticateUserStatus: ApiStatus;
	updateUserStatus: ApiStatus;
	updateRoleStatus: ApiStatus;
	updateLocationStatus: ApiStatus;
	deleteUserStatus: ApiStatus;
	sendVerificationCodeStatus: ApiStatus;
	resetPasswordStatus: ApiStatus;
	getUserDataStatus: ApiStatus;
	getUsersStatus: ApiStatus;
	getClientLocationStatus: ApiStatus;
}

const initialState: UserState = {
	registerUserStatus: 'idle',
	authenticateUserStatus: 'idle',
	updateUserStatus: 'idle',
	updateRoleStatus: 'idle',
	updateLocationStatus: 'idle',
	deleteUserStatus: 'idle',
	sendVerificationCodeStatus: 'idle',
	resetPasswordStatus: 'idle',
	getUserDataStatus: 'idle',
	getUsersStatus: 'idle',
	getClientLocationStatus: 'idle',
};

export const registerUser = createAsyncThunk(
	'user/registerUser',
	async (user: UserObj): Promise<UserObj> => {
		const userId = await UserApi.registerUser(user);
		return { userId };
	}
);

export const loginUser = createAsyncThunk(
	'user/authenticate',
	async (user: UserObj): Promise<LoginUserResponse> => {
		const loginResponse: LoginResponse = await UserApi.loginUser(user);
		if (loginResponse.success) {
		setAuthorizationToken(loginResponse.jwtToken);
			const userResponse: UserResponse = await UserApi.getUser(
				loginResponse.userId
			);
			const loginUserResponse: LoginUserResponse = {
				loginResponse,
				userResponse,
			};
			return loginUserResponse;
		}
		throw new Error('Failed to log in user');
	}
);

export const updateUser = createAsyncThunk(
	'user/updateUser',
	async (
		userRequest: UserRequestBody,
		{ getState }: ThunkAPI
	): Promise<UserResponse> => {
		const userId = (getState() as RootState).user.userId;

		const userResponse: UserResponse = await UserApi.updateUser(
			userId ?? null,
			userRequest
		);
		return userResponse;
	}
);

export const updateRole = createAsyncThunk(
	'user/updateRole',
	async (params: { userId: number; role: Role }): Promise<UserResponse> => {
		const userResponse: UserResponse = await UserApi.updateUser(params.userId, {
			role: params.role,
		});
		return userResponse;
	}
);

export const updateLocation = createAsyncThunk(
	'user/updateLocation',
	async (params: {
		userId: number;
		locationId: number;
	}): Promise<UserResponse> => {
		const userResponse: UserResponse = await UserApi.updateUser(params.userId, {
			locationId: params.locationId,
		});
		return userResponse;
	}
);

export const resetPassword = createAsyncThunk(
	'user/resetPassword',
	async (phoneNumber: string): Promise<void> => {
		return await UserApi.resetPassword(phoneNumber);
	}
);

export const deleteUser = createAsyncThunk(
	'user/deleteUser',
	async (userId: number): Promise<number> => {
		await UserApi.deleteUser(userId);
		return userId;
	}
);

export const sendVerificationCode = createAsyncThunk(
	'user/sendVerificationCode',
	async (phoneNumber: string): Promise<void> => {
		return await UserApi.sendVerificationCode(phoneNumber);
	}
);

export const getUserData = createAsyncThunk(
	'user/getUserData',
	async (_, thunkApi: ThunkAPI) => {
		const userId = (thunkApi.getState() as RootState).user.userId;
		const userResponse: UserResponse = await UserApi.getUser(userId ?? null);
		return userResponse;
	},
	{
		condition: (_, { getState }) => {
			const userId = (getState() as RootState).user.userId;
			if (userId === null) {
				return false;
			}
		},
	}
);

export const getUsers = createAsyncThunk('user/getUsers', async () => {
	const response: UserResponse[] = await UserApi.getUsers();
	return response;
});

export const getClientLocation = createAsyncThunk(
	'user/getClientLocation',
	async () => {
		const response: Location = await LocationApi.getMyLocation();
		return response;
	}
);

export const deleteUserChain =
	(userId: number) => async (dispatch: AppDispatch) => {
		await dispatch(deleteUser(userId)).unwrap();
		dispatch(logoutUserChain());
	};

export const loginUserChain =
	(user: UserObj) => async (dispatch: AppDispatch) => {
		await dispatch(loginUser(user)).unwrap();
		await dispatch(getUserInQueueStatus()).unwrap();
	};

export const logoutUserChain = () => (dispatch: AppDispatch) => {
	dispatch(clearUser());
	dispatch(setCurrentUserInPrinterId(0));
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<UserObj>) => {
			state.userId = action.payload.userId;
			state.firstName = action.payload.firstName;
			state.lastName = action.payload.lastName;
			state.phoneNumber = action.payload.phoneNumber;
		},
		clearUser: (state) => {
			state.jwtToken = undefined;
			state.userId = undefined;
			state.firstName = undefined;
			state.lastName = undefined;
			state.phoneNumber = undefined;
			state.role = undefined;
			setAuthorizationToken();
		},
		initialiseToken: (state) => {
			if (state.jwtToken) {
				setAuthorizationToken(state.jwtToken);
			}
		},
		setGramFilamentUsedCurrentMonth: (state, action: PayloadAction<number>) => {
			state.gramFilamentUsedCurrentMonth =
				state.gramFilamentUsedCurrentMonth ?? 0 + action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(registerUser.pending, (state) => {
				state.registerUserStatus = 'loading';
			})
			.addCase(
				registerUser.fulfilled,
				(state, action: PayloadAction<UserObj>) => {
					state.registerUserStatus = 'idle';
					state.userId = action.payload.userId;
					state.firstName = action.payload.firstName;
					state.lastName = action.payload.lastName;
					state.phoneNumber = action.payload.phoneNumber;
				}
			)
			.addCase(registerUser.rejected, (state, action) => {
				state.registerUserStatus = 'failed';
				userSlice.caseReducers.clearUser(state);
			})
			.addCase(loginUser.pending, (state, action) => {
				state.authenticateUserStatus = 'loading';
			})
			.addCase(loginUser.fulfilled, (state, action) => {
				state.authenticateUserStatus = 'idle';
				const { userId, jwtToken } = action.payload.loginResponse;
				const {
					id,
					locationId,
					firstName,
					lastName,
					phoneNumber,
					role,
					gramFilamentUsedCurrentMonth,
				} = action.payload.userResponse;
				state.userId = userId;
				state.jwtToken = jwtToken;
				state.userId = id;
				state.firstName = firstName;
				state.lastName = lastName;
				state.phoneNumber = phoneNumber;
				state.role = role;
				state.locationId = locationId;
				state.gramFilamentUsedCurrentMonth = gramFilamentUsedCurrentMonth;
			})
			.addCase(loginUser.rejected, (state, action) => {
				state.authenticateUserStatus = 'failed';
				userSlice.caseReducers.clearUser(state);
			})
			.addCase(getUserData.pending, (state, action) => {
				state.getUserDataStatus = 'loading';
			})
			.addCase(getUserData.fulfilled, (state, action) => {
				state.getUserDataStatus = 'idle';
				const {
					id,
					firstName,
					lastName,
					phoneNumber,
					role,
					gramFilamentUsedCurrentMonth,
				} = action.payload;
				state.userId = id;
				state.firstName = firstName;
				state.lastName = lastName;
				state.phoneNumber = phoneNumber;
				state.role = role;
				state.gramFilamentUsedCurrentMonth = gramFilamentUsedCurrentMonth;
			})
			.addCase(getUserData.rejected, (state, action) => {
				state.getUserDataStatus = 'failed';
				userSlice.caseReducers.clearUser(state);
			})
			.addCase(getUsers.pending, (state, action) => {
				state.getUsersStatus = 'loading';
			})
			.addCase(getUsers.fulfilled, (state, action) => {
				state.getUsersStatus = 'idle';
				state.users = action.payload.sort(alphabeticallySort);
			})
			.addCase(getUsers.rejected, (state, action) => {
				state.getUsersStatus = 'failed';
			})
			.addCase(updateUser.pending, (state, action) => {
				state.updateUserStatus = 'loading';
			})
			.addCase(updateUser.fulfilled, (state, action) => {
				state.updateUserStatus = 'idle';
				state.firstName = action.payload.firstName;
				state.lastName = action.payload.lastName;
				state.phoneNumber = action.payload.phoneNumber;
			})
			.addCase(updateUser.rejected, (state, action) => {
				state.updateUserStatus = 'failed';
			})
			.addCase(updateRole.pending, (state, action) => {
				state.updateRoleStatus = 'loading';
			})
			.addCase(updateRole.fulfilled, (state, action) => {
				state.updateRoleStatus = 'idle';
				if (state.users) {
					state.users = getUpdatedUsersState({
						user: action.payload,
						users: state.users,
					});
				} else {
					state.users = [action.payload];
				}
			})
			.addCase(updateRole.rejected, (state, action) => {
				state.updateRoleStatus = 'failed';
			})
			.addCase(updateLocation.pending, (state, action) => {
				state.updateLocationStatus = 'loading';
			})
			.addCase(updateLocation.fulfilled, (state, action) => {
				state.updateLocationStatus = 'idle';
				if (state.users) {
					state.users = getUpdatedUsersState({
						user: action.payload,
						users: state.users,
					});
				} else {
					state.users = [action.payload];
				}
			})
			.addCase(updateLocation.rejected, (state, action) => {
				state.updateLocationStatus = 'failed';
			})
			.addCase(deleteUser.pending, (state, action) => {
				state.deleteUserStatus = 'loading';
			})
			.addCase(deleteUser.fulfilled, (state, action) => {
				state.deleteUserStatus = 'idle';
				state.users = state.users?.filter((user) => user.id !== action.payload);
			})
			.addCase(deleteUser.rejected, (state, action) => {
				state.deleteUserStatus = 'failed';
			})
			.addCase(sendVerificationCode.pending, (state, action) => {
				state.sendVerificationCodeStatus = 'loading';
			})
			.addCase(sendVerificationCode.fulfilled, (state, action) => {
				state.sendVerificationCodeStatus = 'idle';
			})
			.addCase(sendVerificationCode.rejected, (state, action) => {
				state.sendVerificationCodeStatus = 'failed';
			})
			.addCase(resetPassword.pending, (state, action) => {
				state.resetPasswordStatus = 'loading';
			})
			.addCase(resetPassword.fulfilled, (state, action) => {
				state.resetPasswordStatus = 'idle';
			})
			.addCase(resetPassword.rejected, (state, action) => {
				state.resetPasswordStatus = 'failed';
			})
			.addCase(getClientLocation.pending, (state, action) => {
				state.getClientLocationStatus = 'loading';
			})
			.addCase(getClientLocation.fulfilled, (state, action) => {
				state.getClientLocationStatus = 'idle';
				const { id, ipAddress, name } = action.payload;
				state.locationId = id;
				state.locationName = name;
				state.ipAddress = ipAddress;
			})
			.addCase(getClientLocation.rejected, (state, action) => {
				state.getClientLocationStatus = 'failed';
				state.locationId = null;
			});
	},
});

export const {
	setUser,
	clearUser,
	initialiseToken,
	setGramFilamentUsedCurrentMonth,
} = userSlice.actions;

export const userIdSel = (state: RootState) => state.user.userId;
export const firstNameSel = (state: RootState) => state.user.firstName;
export const lastNameSel = (state: RootState) => state.user.lastName;
export const phoneNumberSel = (state: RootState) => state.user.phoneNumber;
export const roleSel = (state: RootState) => state.user.role;
export const locationSel = (state: RootState) => state.user.locationId;
export const locationNameSel = (state: RootState) => state.user.locationName;

export const gramFilamentUsedCurrentMonthSel = (state: RootState) =>
	state.user.gramFilamentUsedCurrentMonth;
export const usersSel = (state: RootState) => state.user.users;
export const authenticateUserStatusSel = (state: RootState) =>
	state.user.authenticateUserStatus;
export const registerUserStatusSel = (state: RootState) =>
	state.user.registerUserStatus;
export const updateUserStatusSel = (state: RootState) =>
	state.user.updateUserStatus;
export const updateRoleStatusSel = (state: RootState) =>
	state.user.updateRoleStatus;
export const updateLocationStatusSel = (state: RootState) =>
	state.user.updateLocationStatus;
export const deleteUserStatusSel = (state: RootState) =>
	state.user.deleteUserStatus;
export const sendVerificationCodeStatusSel = (state: RootState) =>
	state.user.sendVerificationCodeStatus;
export const resetPasswordStatusSel = (state: RootState) =>
	state.user.sendVerificationCodeStatus;
export const getUserDataStatusSel = (state: RootState) =>
	state.user.getUserDataStatus;
export const locationIdSel = (state: RootState) => state.user.locationId;
export const getClientLocationStatusSel = (state: RootState) =>
	state.user.getClientLocationStatus;

export const showQueueSelector = createSelector(
	userIdSel,
	locationIdSel,
	(userId, locationId) => userId || locationId
);
export const acceptedLocationSelector = createSelector(
	locationIdSel,
	(locationId) =>
		locationId !== null && locationId !== undefined && locationId > 0
);

export default userSlice.reducer;
