import withTheme from '@mui/styles/withTheme';
import styled from 'styled-components';

export namespace Alignment {
	interface CenterAlignHorVerWrapperProps {
		minheight?: string;
	}
	export const CenterAlignHorVerWrapper =
		withTheme(styled.div<CenterAlignHorVerWrapperProps>`
			display: grid;
			place-items: center;
			min-height: ${(props) => props.minheight && props.minheight};
		`);

	export const FlexRowCenterWrapper = withTheme(styled.div`
		display: flex;
		flex-direction: 'row';
		justify-content: center;
		align-items: center;
	`);
}
