import { lighten, Typography } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Footer = () => {
	const date: Date = new Date();
	const { t } = useTranslation();

	return (
		<FooterWrapper>
			<Typography variant="subtitle1">LabQ</Typography>
			<Typography variant="subtitle1">
				{t('Ta kontakt')}: #sta-labben {t('på')} Slack
			</Typography>
			<Typography variant="subtitle1">© Bouvet {date.getFullYear()}</Typography>
		</FooterWrapper>
	);
};

const FooterWrapper = withTheme(styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	min-height: 10rem;
	background: ${(props) => lighten(props.theme.palette.primary.main, 0.07)};
	color: ${(props) => props.theme.palette.primary.contrastText};

	display: flex;
	align-items: center;
	justify-content: space-evenly;

	${(props) => props.theme.breakpoints.down('sm')} {
		flex-direction: column;
	}
`);

export default Footer;
