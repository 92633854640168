import { useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { motion } from 'framer-motion';
import React, { memo, useEffect } from 'react';
import styled from 'styled-components';
import {
	enqueuedUsersListTransition,
	enqueuedUsersListVariants,
} from '../../../animations/framer-motion/enqueuedUsersList';
import Feedback from '../../../general-components/Feedback';
import { EnqueuedUser } from '../../../models/Queue.models';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { activePrinterIdSel, } from '../../../redux/Printer/printerSlice';
import {
	enqueuedUsersSel,
	enqueuedUsersStatusSel,
	unenqueueCurrentUserStatusSel,
	enqueueCurrentUserStatusSel,
	startOrProlongPrintStatusSel,
	moveBackwardsInQueueStatusSel,
	deleteFirstUserInQueueStatusSel,
	getEnqueuedUsers,
} from '../../../redux/Queue/queueSlice';
import { Alignment } from '../../../styledcomponents/General/alignment';
import QueueItem from './QueueItem';
import BeatLoader from 'react-spinners/BeatLoader';
import MainQueueActions from './MainQueueActions';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../redux/store';

export interface QueueListProps { }

const QueueList: React.FC<QueueListProps> = () => {
	const theme = useTheme();
	const activePrinterId = useAppSelector(activePrinterIdSel);


	const activePrinterState = useAppSelector((state: RootState) =>
		state.printer.printers.find((printer: { id: any; }) => printer.id === state.printer.activePrinterId)?.printerState
	);

	const activePrinter = useAppSelector((state: RootState) =>
		state.printer.printers.find((printer: { id: any; }) => printer.id === activePrinterId)?.modelName
	);


	const { t } = useTranslation();
	const enqueuedUsers = useAppSelector(enqueuedUsersSel);
	const enqueuedUsersStatus = useAppSelector(enqueuedUsersStatusSel);

	const enqueueCurrentUserStatus = useAppSelector(enqueueCurrentUserStatusSel);
	const unenqueueCurrentUserStatus = useAppSelector(
		unenqueueCurrentUserStatusSel
	);
	const startOrProlongPrintStatus = useAppSelector(
		startOrProlongPrintStatusSel
	);
	const moveBackwardsInQueueStatus = useAppSelector(
		moveBackwardsInQueueStatusSel
	);
	const deleteFirstUserInQueueStatus = useAppSelector(
		deleteFirstUserInQueueStatusSel
	);


	const dispatch = useAppDispatch();

	useEffect(() => {
		if (
			enqueueCurrentUserStatus === 'idle' ||
			unenqueueCurrentUserStatus === 'idle' ||
			startOrProlongPrintStatus === 'idle' ||
			moveBackwardsInQueueStatus === 'idle' ||
			deleteFirstUserInQueueStatus === 'idle'
		) {
			dispatch(getEnqueuedUsers());
		}
	}, [
		dispatch,
		enqueueCurrentUserStatus,
		unenqueueCurrentUserStatus,
		startOrProlongPrintStatus,
		moveBackwardsInQueueStatus,
		deleteFirstUserInQueueStatus,
	]);

	if (activePrinterId === null) return <></>;

	return (
		<Wrapper>
			<Alignment.CenterAlignHorVerWrapper minheight="4rem">

				<MainQueueActions />

			</Alignment.CenterAlignHorVerWrapper>

			<Alignment.CenterAlignHorVerWrapper minheight="2.2rem">
				{enqueuedUsersStatus === 'loading' && (
					<BeatLoader color={theme.palette.primary.light} />
				)}
			</Alignment.CenterAlignHorVerWrapper>

			{enqueuedUsersStatus === 'failed' && (
				<FeedbackOuterWrapper>
					<Feedback
						type="error"
						message={t('Kunne ikke laste inn kø. Prøv på nytt')}
					/>
				</FeedbackOuterWrapper>
			)}

			{activePrinterState === 'Disabled' && (
				<FeedbackOuterWrapper>
					<Feedback
						type="error"
						message={t('Printeren er dekativert.')}
					/>
				</FeedbackOuterWrapper>
			)}

			{!enqueuedUsers.length && enqueuedUsersStatus !== 'loading' && activePrinterState !== "Disabled" && (
				<FeedbackOuterWrapper>
					<Feedback
						type="success"
						message={
							<span>
								{t('Det er ingen i kø for ')} <strong>{activePrinter}</strong>
							</span>
						}
					/>
				</FeedbackOuterWrapper>
			)}

			<QueueListWrapper
				key="queueListWrapper"
				initial="initial"
				animate="in"
				variants={enqueuedUsersListVariants}
				transition={enqueuedUsersListTransition}
			>
				{enqueuedUsers.map((userQueueItem: EnqueuedUser) => (
					<QueueItem
						key={`${userQueueItem.queueItemId}${userQueueItem.queueState}`}
						enqueuedUser={userQueueItem} onTheLab={false}
						/>
				))}
			</QueueListWrapper>
		</Wrapper>
	);
};

const Wrapper = withTheme(styled.div`
	min-height: 12rem;
`);

export const QueueListWrapper = withTheme(styled(motion.div)`
	width: 100%;
	box-sizing: border-box;
	padding: 1rem;
	${(props) => props.theme.breakpoints.down('sm')} {
		padding: 1rem 0;
	}
`);

export const FeedbackOuterWrapper = styled(motion.div)`
	height: 3.65rem;
	display: flex;
	justify-content: center;
`;

export default memo(QueueList);
