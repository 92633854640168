import { differenceInCalendarDays, format, parseISO } from 'date-fns';

export namespace TimeUtils {
	export interface HHMMSS {
		hours: number;
		minutes: number;
		seconds: number;
		HtMmDisplayString: string;
		HtMmDisplayMinRoundUpString: string;
	}

	export const toHHMMSS = (totalSeconds: number): HHMMSS => {
		const hours = Math.floor(totalSeconds / 3600);
		const minutes = Math.floor(totalSeconds / 60) % 60;
		const seconds = totalSeconds % 60;

		const HtMmDisplayString = `${hours}t ${minutes}m`;
		const HtMmDisplayMinRoundUpString = `${hours}t ${minutes + 1}m`;

		return {
			hours,
			minutes,
			seconds,
			HtMmDisplayString,
			HtMmDisplayMinRoundUpString,
		};
	};

	export const dateToWordsOr_dd_MM_yy = (date: Date): string => {
		const daysDifference: number = differenceInCalendarDays(new Date(), date);
		let displayString = '';
		switch (daysDifference) {
			case 1:
				displayString = 'i går';
				break;
			case 0:
				displayString = 'i dag';
				break;
			case -1:
				displayString = 'i morgen';
				break;
			default:
				displayString = format(date, 'dd.MM.yy');
				break;
		}
		return displayString;
	};

	export const dateToWordOr_dd_MM_yyyy_HH_mm = (date: Date): string => {
		return `${dateToWordsOr_dd_MM_yy(date)} kl: ${format(date, 'HH:mm')}`;
	};

	export const dateToTwoStringArray = (date: Date): string[] => {
		return [`${dateToWordsOr_dd_MM_yy(date)}`, `kl: ${format(date, 'HH:mm')}`];
	};

	export const oneMinuteupdateIntervalMS = 60000;

	export const parseISODate = (date: string | null): Date | null => {
		return date ? parseISO(date) : null;
	};

	export const getTimeDifference = (
		startDate: Date | null,
		endDate: Date | null
	): string => {
		if (startDate === null || endDate === null) return '0';

		const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
		const minutes = Math.floor(timeDifference / 60000);

		return timeInMinutesToString(minutes);
	};

	export const timeInMinutesToString = (
		minutes: number | undefined
	): string => {
		if (!minutes) return '';

		const hours = Math.floor(minutes / 60);
		const days = Math.floor(hours / 24);

		const formattedDays = days > 0 ? `${days}d` : '';
		const formattedHours = hours % 24 > 0 ? `${hours % 24}h` : '';
		const formattedMinutes = minutes % 60 > 0 ? `${minutes % 60}min` : '';

		return [formattedDays, formattedHours, formattedMinutes]
			.filter(Boolean)
			.join(' ');
	};
}
