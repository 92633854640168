import styled from 'styled-components';
import { Button, CircularProgress, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import {
	firstUserInQueueSel,
	getEnqueuedUsers,
	lastUserInQueueSel,
	moveBackwardsInQueue,
	moveBackwardsInQueueStatusSel,
} from '../../../redux/Queue/queueSlice';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import {
	QueueState,
	EnqueuedUser,
	CompletedEnqueuedUser,
} from '../../../models/Queue.models';
import EstimatePrintTime from './EstimatePrintTime';
import { userIdSel } from '../../../redux/User/userSlice';
import GetAppIcon from '@mui/icons-material/GetApp';
import UnenqueueUserButton from './UnenqueueUserButton';
import PrintIsFinished from './PrintIsFinished';
import RestartPrintButton from './RestartPrintButton';
import { canRestartPrint } from './queueUtils';
import { onTheLabSel } from '../../../redux/Location/locationSlice';
import { useTranslation } from 'react-i18next';
import PreparationButton from './PreparationButton';
import { useSelector } from 'react-redux';
import { canAddPreparationTime } from './canAddPreperationTime';

interface QueueActionsProps {
	queueState: QueueState;
	userQueueItem: EnqueuedUser;
	enqueuedUser: EnqueuedUser | CompletedEnqueuedUser;
}

const QueueActions = ({
	queueState,
	userQueueItem,
	enqueuedUser,
}: QueueActionsProps) => {
	const theme = useTheme();
	const dispatch = useAppDispatch();
	const confirm = useConfirm();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const lastUserInQueue = useAppSelector(lastUserInQueueSel);
	const userId = useAppSelector(userIdSel);
	const moveBackwardsInQueueStatus = useAppSelector(
		moveBackwardsInQueueStatusSel
	);
	const firstUserInQueue = useSelector(firstUserInQueueSel);
	const onTheLab = useAppSelector(onTheLabSel);

	const confirmMoveBackwardsInQueue = async () => {
		try {
			if (userQueueItem.postponesLeft === 1) {
				await confirm({
					title: t('Er du sikker?'),
					description: t(`Dette er siste gang du får utsette print. `),
					confirmationText: t('Flytt bakover'),
					confirmationButtonProps: {
						color: 'secondary',
						variant: 'contained',
						style: { background: theme.palette.secondary.main },
					},
					cancellationText: t('Avbryt'),
				});
			} else {
				await confirm({
					title: t('Er du sikker?'),
					description:
						t(
							'Dette vil flytte deg ett steg bakover i kø. Du kan utsette print '
						) +
						`${userQueueItem.postponesLeft}` +
						t(' ganger til.'),
					confirmationText: t('Flytt bakover'),
					confirmationButtonProps: {
						color: 'secondary',
						variant: 'contained',
						style: { background: theme.palette.secondary.main },
					},
					cancellationText: t('Avbryt'),
				});
			}
			handleMoveBackwardsInQueue();
		} catch (e) {}
	};

	const handleMoveBackwardsInQueue = async () => {
		try {
			if (!userId) {
				throw new Error();
			}
			await dispatch(moveBackwardsInQueue(userId)).unwrap();
			dispatch(getEnqueuedUsers());

			enqueueSnackbar(t(`Du har blitt flyttet lenger bak i køen.`), {
				variant: 'success',
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'center',
				},
			});
		} catch (e) {
			enqueueSnackbar(
				t(`Noe gikk galt. Du ble ikke flyttet lenger bak i køen`),
				{
					variant: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'center',
					},
				}
			);
		}
	};

	return (
		<QueueActionsWrapper>
			{onTheLab &&
				queueState === QueueState.ReadyToPrint &&
				userQueueItem.userId === userId && (
					<QueueActionItem>
						<EstimatePrintTime estimateType="estimateTime" />
					</QueueActionItem>
				)}

			{onTheLab &&
				(queueState === QueueState.Printing ||
					queueState === QueueState.Finished) &&
				userQueueItem.userId === userId && (
					<QueueActionItem>
						<EstimatePrintTime estimateType="addTime" />
					</QueueActionItem>
				)}
			{onTheLab &&
				(queueState === QueueState.Printing ||
					queueState === QueueState.Finished) &&
				userQueueItem.userId === userId && (
					<QueueActionItem>
						<RestartPrintButton disabled={!canRestartPrint(enqueuedUser)} />
					</QueueActionItem>
				)}

			{onTheLab &&
				userId === userQueueItem.userId &&
				(queueState === QueueState.Printing ||
					queueState === QueueState.Finished) && (
					<QueueActionItem>
						<PrintIsFinished user={userQueueItem} />
					</QueueActionItem>
				)}

			{(queueState === QueueState.InQueue ||
				queueState === QueueState.ReadyToPrint) &&
				userQueueItem.userId === userId &&
				userQueueItem.position !== lastUserInQueue.position &&
				userQueueItem.postponesLeft > 0 && (
					<QueueActionItem>
						<Button
							variant="contained"
							color="secondary"
							endIcon={
								moveBackwardsInQueueStatus === 'loading' ? (
									<CircularProgress size={20} />
								) : (
									<GetAppIcon />
								)
							}
							onClick={confirmMoveBackwardsInQueue}
							disabled={moveBackwardsInQueueStatus === 'loading'}
						>
							{t('Flytt bakover')}
						</Button>
					</QueueActionItem>
				)}

			{userId === userQueueItem.userId && (
				<QueueActionItem>
					<UnenqueueUserButton />
				</QueueActionItem>
			)}
			{onTheLab &&
				userId === userQueueItem.userId &&
				queueState === QueueState.ReadyToPrint && (
					<QueueActionItem>
						{firstUserInQueue && (
							<PreparationButton
								queueItem={firstUserInQueue}
								disabled={!canAddPreparationTime(firstUserInQueue)}
							/>
						)}
					</QueueActionItem>
				)}
		</QueueActionsWrapper>
	);
};

const QueueActionsWrapper = withTheme(styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	${(props) => props.theme.breakpoints.down('sm')} {
		flex-direction: column;
		justify-content: center;
	}
`);

const QueueActionItem = withTheme(styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0rem 1rem;
	${(props) => props.theme.breakpoints.down('sm')} {
		margin: 0.8rem 1.5rem;
	}
`);

export default QueueActions;
