import { Button, useTheme } from '@mui/material';
import withTheme from '@mui/styles/withTheme';
import React from 'react';
import styled from 'styled-components';
import { StyledNavLink } from '../styledcomponents/ReactRouter/ReactRouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHome,
	faSignInAlt,
	faUserPlus,
	faTools,
	faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../redux/hooks';
import {
	acceptedLocationSelector,
	roleSel,
	userIdSel,
} from '../redux/User/userSlice';
import ProfileMenu from './ProfileMenu';
import { onTheLabSel } from '../redux/Location/locationSlice';
import SelectLanguage from './SelectLanguage';
import { useTranslation } from 'react-i18next';

export interface NavbarLinksProps {
	onClickCallback?: Function;
}

const NavbarLinks: React.FC<NavbarLinksProps> = ({ onClickCallback }) => {
	const theme = useTheme();
	const userId = useAppSelector(userIdSel);
	const role = useAppSelector(roleSel);
	const activeNavLinkStyles = ({isActive}: {isActive: boolean}) => isActive ? { color: theme.palette.secondary.main } : undefined;
	const acceptedLocation = useAppSelector(acceptedLocationSelector);
	const onTheLab = useAppSelector(onTheLabSel);
	const { t } = useTranslation();

	const handleCallback = () => onClickCallback && onClickCallback();

	return (
		<>
			<StyledNavLink
				to={`/${onTheLab ? 'lab' : ''}`}
				style={activeNavLinkStyles}
			>
				<NavbarLinkButton
					startIcon={<FontAwesomeIcon icon={faHome} />}
					color="secondary"
					onClick={handleCallback}
				>
					{t('Hjem')}
				</NavbarLinkButton>
			</StyledNavLink>

			{(role === 'Admin' || role === 'SuperAdmin') && (
				<StyledNavLink to={'/admin'} style={activeNavLinkStyles}>
					<NavbarLinkButton
						startIcon={<FontAwesomeIcon icon={faTools} />}
						color="secondary"
						onClick={handleCallback}
					>
						Admin
					</NavbarLinkButton>
				</StyledNavLink>
			)}

			{!userId && (
				<>
					{acceptedLocation && (
						<StyledNavLink to={'/register'} style={activeNavLinkStyles}>
							<NavbarLinkButton
								startIcon={<FontAwesomeIcon icon={faUserPlus} />}
								color="secondary"
								onClick={handleCallback}
							>
								{t('Registrer')}
							</NavbarLinkButton>
						</StyledNavLink>
					)}

					<StyledNavLink to={'/login'} style={activeNavLinkStyles}>
						<NavbarLinkButton
							startIcon={<FontAwesomeIcon icon={faSignInAlt} />}
							color="secondary"
							onClick={handleCallback}
						>
							{t('Logg inn')}
						</NavbarLinkButton>
					</StyledNavLink>
				</>
			)}

			<StyledNavLink to="/faq" style={activeNavLinkStyles}>
				<NavbarLinkButton
					startIcon={<FontAwesomeIcon icon={faQuestionCircle} />}
					color="secondary"
					onClick={handleCallback}
				>
					FAQ
				</NavbarLinkButton>
			</StyledNavLink>

			{userId && <ProfileMenu />}
			<SelectLanguage />
		</>
	);
};

const NavbarLinkButton = withTheme(styled(Button)`
	color: inherit;
`);

export default NavbarLinks;
