import { compareDesc } from 'date-fns/esm';
export namespace DateUtils {
	export const getLastDateFromList = (dates: Date[]): Date => {
		const datesSortedDesc = dates.sort(compareDesc);
		return datesSortedDesc[0];
	};

	export function formatDateString(dateString: string | null) {
		if (!dateString) {
			return 'N/A';
		}
		const date = new Date(dateString);
		if (isNaN(date.getTime())) {
			return 'Invalid Date';
		}
		const options = {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
			//hour: '2-digit',
			//minute: '2-digit',
			//second: '2-digit',
		};

		return date.toLocaleDateString(
			undefined,
			options as Intl.DateTimeFormatOptions
		);
	}
}


