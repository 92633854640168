import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import StatBox from './StatBox';
import { TimeUtils } from '../../../utils/time.utils';
import { AggregateStats } from '../../../redux/Stats/stats.models';
import { WeightUtils } from '../../../utils/weight.utils';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

interface Props {
	data: AggregateStats | undefined;
	isLoading: boolean;
	isAdmin: boolean;
}

const MainStats: React.FC<Props> = ({ data, isLoading, isAdmin }: Props) => {
	const { t } = useTranslation();

	const displayTotalWeight = (weight: number | undefined) => {
		if (weight === undefined) return '0';
		return isAdmin ? WeightUtils.formatWeight(weight) : weight + 'g';
	};

	return (
		<StatsWrapper isadmin={String(isAdmin)}>
			<Box>
				<Divider sx={{ width: '100%', mt: 7 }}>
					<Typography variant="h5" sx={{ m: 2 }}>
						{t('Antall utskrifter')}
					</Typography>
				</Divider>
				<Box sx={{ display: 'flex' }}>
					<StatBox
						text={t('Vellykket') + ' / ' + t('Mislyktes')}
						stat={data ? data.successfulPrints : 0}
						statStyle={{ color: 'green' }}
						maxStyle={{ color: 'red' }}
						loading={isLoading}
						max={data ? data.totalPrints - data.successfulPrints : 0}
					></StatBox>
					<StatBox
						text={t('Total')}
						stat={data ? data.totalPrints : 0}
						loading={isLoading}
					></StatBox>
				</Box>
			</Box>

			<Box>
				<Divider sx={{ width: '100%', mt: 7 }}>
					<Typography sx={{ m: 2 }} variant="h5">
						{t('Utskriftstid')}
					</Typography>
				</Divider>
				<Box sx={{ display: 'flex' }}>
					<StatBox
						text={t('Gjennomsnitt')}
						stat={
							data ? TimeUtils.timeInMinutesToString(data.printTimeAverage) : 0
						}
						loading={isLoading}
					></StatBox>
					<StatBox
						text={t('Total')}
						stat={
							data ? TimeUtils.timeInMinutesToString(data.printTimeTotal) : 0
						}
						loading={isLoading}
					></StatBox>
				</Box>
			</Box>

			<Box>
				<Divider sx={{ width: '100%', mt: 7 }}>
					<Typography sx={{ m: 2 }} variant="h5">
						{t('Filamentbruk')}
					</Typography>
				</Divider>
				<Box sx={{ display: 'flex' }}>
					<StatBox
						text={t('Gjennomsnitt')}
						stat={data ? `${data.filamentUsageAverage}g` : 0}
						loading={isLoading}
					></StatBox>
					<StatBox
						text={t('Total')}
						stat={displayTotalWeight(data?.filamentUsageTotal)}
						max={isAdmin ? undefined : '500g'}
						statStyle={
							!isAdmin && data && data.filamentUsageTotal > 500
								? { color: 'red' }
								: {}
						}
						loading={isLoading}
					></StatBox>
				</Box>
			</Box>

			{isAdmin && (
				<Box>
					<Divider sx={{ width: '100%', mt: 7 }}>
						<Typography sx={{ m: 2 }} variant="h5">
							{t('Brukere')}
						</Typography>
					</Divider>

					<Box sx={{ display: 'flex' }}>
						<StatBox
							text={t('Gjennomsnittlig filamentbruk')}
							stat={data ? `${data.userAverageFilamentUsage}g` : 0}
							max={isAdmin ? undefined : '500g'}
							loading={isLoading}
						></StatBox>
						<StatBox
							text={t('Total')}
							stat={data ? `${data.userCount}` : 0}
							loading={isLoading}
						></StatBox>
					</Box>
				</Box>
			)}
		</StatsWrapper>
	);
};

interface StatsWrapperProps {
	isadmin: string;
}

const StatsWrapper = styled(Box)<StatsWrapperProps>`
	display: flex;
	flex-direction: ${(props) => (props.isadmin === 'true' ? 'row' : 'column')};
	flex-wrap: wrap;
	width: 100%;
	> * {
		flex: ${(props) => (props.isadmin === 'true' ? '1 1 40%' : '')};
		margin: ${(props) => (props.isadmin === 'true' ? '0 4rem' : '')};
	}
`;

export default MainStats;
